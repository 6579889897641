import React, { useState, useEffect } from "react";
import { getAllQuestions } from "../api";
const MultiSelectDropdown = ({
  questions,
  setQuestion,
  selectedCourse,
  selectedClasses,
  selectedSubject,
  selectedChapter,
  selectedTopic,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [submenuOpen, setSubmenuOpen] = useState(null);
  const [allQuestion, setAllQuestion] = useState([]);
  const [filterQuestion, setFilterQuestions] = useState([questions]);

  const [types, setTypes] = useState([]);
  const [levels, setLevels] = useState([]);
  const [sources, setSources] = useState([]);
  const [marks, setMarks] = useState([]);


  const options = [
    { label: "Type", submenu: types },
    { label: "Difficulty", submenu: levels },
    { label: "Source", submenu: sources },
    { label: "Marks", submenu: marks },
    { label: "Use", submenu: ["Used", "Unused"] },
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    if (selectedItems.includes(option)) {
      setSelectedItems(selectedItems.filter((item) => item !== option));
    } else {
      setSelectedItems([...selectedItems, option]);
    }
  };

  const handleSubmenuToggle = (index) => {
    if (submenuOpen === index) {
      setSubmenuOpen(null);
    } else {
      setSubmenuOpen(index);
    }
  };

  const dropdownHandler = (questions) => {
    let types = new Set();
    let levels = new Set();
    let sources = new Set();
    let marks = new Set();
    

    allQuestion.forEach((question) => {
      
      types.add(question.type);
      levels.add(question.level);
      sources.add(question.source);
      marks.add(question.marks);
      
    });
    setTypes(Array.from(types));
    setLevels(Array.from(levels));
    setSources(Array.from(sources));
    setMarks(Array.from(marks));
    
  };

  const filterQuestions = () => {
    let filteredQuestions = allQuestion;

    if (selectedItems.length > 0) {
      filteredQuestions = filteredQuestions.filter((question) => {
        const selectedTypes = selectedItems.filter((item) =>
          types.includes(item)
        );
        const selectedLevels = selectedItems.filter((item) =>
          levels.includes(item)
        );
        const selectedSources = selectedItems.filter((item) =>
          sources.includes(item)
        );
        const selectedMarks = selectedItems.filter((item) =>
          marks.includes(item)
        );

        return (
          (selectedTypes.length === 0 ||
            selectedTypes.includes(question.type)) &&
          (selectedLevels.length === 0 ||
            selectedLevels.includes(question.level)) &&
          (selectedSources.length === 0 ||
            selectedSources.includes(question.source)) &&
          (selectedMarks.length === 0 || selectedMarks.includes(question.marks))
        );
      });
    }

    setQuestion(filteredQuestions);
  };

  // fetch
  const fetchAllQuestions = async () => {
    try {
      const result = await getAllQuestions(
        selectedCourse,
        selectedClasses,
        selectedSubject,
        selectedChapter,
        selectedTopic
      );
      const questions = result.data;
      setAllQuestion(questions);
    } catch (error) {
      console.error("Error fetching question:", error);
    }
  };

  useEffect(() => {
    fetchAllQuestions();
  }, []);

  useEffect(() => {
    console.log(filterQuestion);
    dropdownHandler(questions);
    
  }, [questions, filterQuestion]);

  useEffect(() => {
    filterQuestions();
  }, [selectedItems]);

  return (
    <div className="relative inline-block text-left w-64">
      <div>
        <button
          type="button"
          onClick={toggleDropdown}
          className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {selectedItems.length > 0
            ? selectedItems.join(", ")
            : "Select filter"}
          <svg
            className="ml-2 -mr-1 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map((option, index) => (
              <div key={index} className="flex flex-col">
                <div
                  className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSubmenuToggle(index)}
                >
                  <span className="text-gray-700">{option.label}</span>
                  <svg
                    className="ml-auto h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                {submenuOpen === index && option.submenu && (
                  <div className="ml-4">
                    {option.submenu.map((suboption) => (
                      <div
                        key={suboption}
                        className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSelect(suboption)}
                      >
                        <input
                          type="checkbox"
                          className="form-checkbox h-5 w-5 text-indigo-600"
                          checked={selectedItems.includes(suboption)}
                          onChange={() => handleSelect(suboption)}
                        />
                        <span className="ml-2 text-gray-700">{suboption}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
