import { useState } from "react";
import {
  Box,
  Stack,
  Chip,
  Container,
  Modal,
  Typography,
  Button,
} from "@mui/material";
import { Link, Outlet } from "react-router-dom";

const pages = ["Home", "Upload", "CSV Upload", "Download"];

export default function HeaderComponent({setWatermark}) {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setWatermark(inputValue);
    setInputValue("");
    setOpenModal(false);
  };

  return (
    <>
      <Container maxWidth={"xl"}>
        <Box className="rounded-full m-4 p-2 bg-[#00366C]">
          <Stack direction={"row"} justifyContent={"center"} p={0.7}>
            <Link to={"/"}>
              <h1 className="text-[#ffffff] ps-4 font-semibold text-lg lg:text-2xl">
                Exam Preparation
              </h1>
            </Link>

            <Box sx={{ marginLeft: "auto", justifyContent: "center" }}>
              {pages.map((item, index) => (
                <Link
                  key={item}
                  to={
                    index === 0
                      ? "/"
                      : index === 1
                      ? "/upload"
                      : index === 2
                      ? "/csvupload"
                      : "/download"
                  }
                >
                  <Chip
                    key={index}
                    label={item}
                    clickable
                    sx={{
                      fontSize: "1rem",
                      bgcolor: "transparent",
                      color: "white",
                      marginX: "10px",
                      ":hover": { bgcolor: "#28CC9E" },
                    }}
                  />
                </Link>
              ))}
              <Chip
                label={"WaterMark"}
                clickable
                onClick={handleOpenModal}
                sx={{
                  fontSize: "1rem",
                  bgcolor: "transparent",
                  color: "white",
                  marginX: "10px",
                  ":hover": { bgcolor: "#28CC9E" },
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Container>

      {/* Modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Watermark
          </Typography>
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This is where you can manage your watermark settings.
          </Typography> */}
          <form onSubmit={handleSubmit} className="flex mt-2">
            <input
              type="text"
              value={inputValue}
              onChange={handleInput}
              placeholder="Enter text"
              className="border flex-1 border-blue-300 p-1 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="bg-blue-500 text-white mx-2 p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </form>
          <Button onClick={handleCloseModal} sx={{ mt: 2, color: "red" }}>
            Close
          </Button>
        </Box>
      </Modal>

      {/* Outlet */}
      <Outlet />
    </>
  );
}
